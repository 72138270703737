import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import type { PermissionEnum } from '@pi/pi-common/src/ui-dtos/permission.dtos';

import { GlobalMessagesService } from '../../lib/services/global-messages.service';
import { PermissionService } from '../../lib/services/permission/permission.service';
import type { PermissionComparisonType } from '../../shared/resolvers/has-permissions.resolver';
import { hasPermissionsFn } from '../../shared/resolvers/has-permissions.resolver';
import { accessDenied } from './access-denied';

async function hasPermissionsGuardFn(
    route: ActivatedRouteSnapshot,
    permissionService: PermissionService,
    globalMessagesService: GlobalMessagesService,
    permissions: PermissionEnum[] | PermissionEnum,
    comparison: PermissionComparisonType
): Promise<true | UrlTree> {
    // Support both a single permission as well as an array
    const result = await hasPermissionsFn(permissionService, permissions, comparison);

    if (!result) {
        return accessDenied(route, globalMessagesService);
    }
    return result;
}

export const hasPermissionsGuard =
    (permissions: PermissionEnum[] | PermissionEnum, comparison: PermissionComparisonType = 'EVERY') =>
    (route: ActivatedRouteSnapshot) => {
        const permissionService = inject(PermissionService);
        const globalMessagesService = inject(GlobalMessagesService);
        return hasPermissionsGuardFn(route, permissionService, globalMessagesService, permissions, comparison);
    };
